import { ENV } from '@/config/env';
import i18n from '@/config/i18n';

const lng = i18n.language;

export const links = {
  privacyPolicy: `/${lng}/privacy-policy`,
  termsOfUse: ENV.VITE_TERMS_OF_USE_URL,
  login: {
    main: `/${lng}/`,
    storeSelection: `/${lng}/store-selection`,
    forgotPassword: `/${lng}/forgot-password`,
    resetPassword: `/${lng}/reset-password`,
    activateUser: `/${lng}/activate-user`,
    acceptTerms: `/${lng}/accept-terms`,
  },
  quotes: {
    list: `/${lng}`,
    new: `/${lng}/quotes/new`,
    steps: `/${lng}/quotes/new/steps`,
    newProduct: `/${lng}/quotes/newProduct`,
    newContract: `/${lng}/quotes/contract/new`,
    contractSteps: `/${lng}/quotes/contract/new/steps`,
    cart: (dealId = ':dealId') => `/${lng}/quotes/cart/${dealId}`,
  },
  contract: `/${lng}/contract`,
  userProfile: {
    profile: `/${lng}/user-profile/profile`,
    password: `/${lng}/user-profile/password`,
    userProfileOptions: `/${lng}/user-profile/`,
  },
  pricer: `/${lng}/pricer`,
  clients: `/${lng}/clients`,
  dealer: `/${lng}/dealer`,
  dealers: `/${lng}/dealers`,
  store: `/${lng}/store`,
  stores: `/${lng}/stores`,
  reports: `/${lng}/reports`,
  settings: {
    users: `/${lng}/settings/users`,
    planProductsParts: `/${lng}/settings/plan-products-parts`,
    contractTemplate: `/${lng}/settings/contract-template`,
    dealer: `/${lng}/settings/dealer`,
    store: `/${lng}/settings/store`,
    products: `/${lng}/settings/products`,
    models: `/${lng}/settings/models`,
    materials: `/${lng}/settings/materials`,
    plans: `/${lng}/settings/plans`,
    globalConfig: `/${lng}/settings/global-settings`,
    settingsOptions: `/${lng}/settings/`,
  },
};

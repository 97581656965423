/* eslint-disable @typescript-eslint/ban-ts-comment */
import { format as formatDate, isDate } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

import zodBR from './zod.json';

const locales = { ptBR, enUS };

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'pt-BR',
    supportedLngs: ['pt-BR', 'en'],
    ns: ['common'],
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value) && format && lng) {
          // @ts-ignore
          const locale = locales[lng] || ptBR;

          return formatDate(value, format, { locale });
        }

        return value;
      },
    },
    backend: {
      loadPath: '../../../../locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['path', 'querystring', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
    },
  });

i18n.init({
  partialBundledLanguages: true,
  resources: {
    'pt-BR': {
      zod: zodBR,
    },
  },
});

z.setErrorMap(makeZodI18nMap());

export default i18n;

import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'flex',
    'items-center',
    'justify-center',
    'shadow-default',
    'hover:transition-all',
    'cursor-pointer',
    'disabled:opacity-50 disabled:cursor-not-allowed',
    'rounded-md',
    'w-full',
    'min-w-max',
    'p-1.5',
    'text-xs',
  ],
  {
    variants: {
      variant: {
        primary: ['text-white', 'bg-brand-pure', 'hover:bg-brand-600'],
        primarySoft: [
          'text-brand-400',
          'font-semibold',
          'bg-brand-50',
          'hover:bg-brand-200',
        ],
        secondary: [
          'text-brand-800',
          'bg-white',
          'hover:bg-brand-100',
          'border',
          'border-neutral-300',
          'shadow',
        ],
        success: [
          'text-neutral-800',
          'bg-sustainability-green-800',
          'hover:bg-sustainability-green-900',
        ],
        successSoft: [
          'text-sustainability-green-900',
          'bg-sustainability-green-50',
          'hover:bg-sustainability-green-100',
          'border',
          'border-sustainability-green-300',
        ],
        danger: [
          'text-white',
          'bg-driver-centricity-800',
          'hover:bg-driver-centricity-900',
        ],
        dangerSoft: [
          'text-driver-centricity-800',
          'bg-driver-centricity-50',
          'hover:bg-driver-centricity-300',
          'border',
          'border-driver-centricity-300',
        ],
      },
      size: {
        xs: ['p-1.5', 'text-xs'],
        small: ['p-2.5', 'text-sm'],
        medium: ['sm:p-3.5', 'sm:font-semibold'],
        large: ['sm:p-5', 'sm:font-semibold'],
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
  },
);

export const loading = cva([
  'animate-spin',
  'inline-block',
  'w-4',
  'h-4',
  'border-4',
  'border-current',
  'border-t-transparent',
  'text-white',
  'rounded-full',
]);

export const content = cva(['flex', 'justify-center', 'items-center', 'gap-3']);

export const containerIcon = cva(['flex', 'items-center', 'justify-center']);

export const text = cva([
  'block',
  'group-[.flex]:hidden',
  'md:group-[.flex]:block',
]);

export const textDropDown = cva([
  'block',
  'text-neutral-600',
  'text-sm',
  'md:text-base',
  'font-medium',
  'group-[.flex]:hidden',
  'md:group-[.flex]:block',
]);

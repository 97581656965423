/* eslint-disable react/button-has-type */
import { VariantProps } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import * as style from './styles';

type ButtonProps = VariantProps<typeof style.container>;

export type ButtonSize = 'xs' | 'small' | 'medium' | 'large';

export type IconAlignment = 'start' | 'end';

export interface IButton extends React.ComponentPropsWithRef<'button'> {
  text?: string;
  variant?: ButtonProps['variant'];
  size?: ButtonSize;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  isLoading?: boolean;
  isDropDown?: boolean;
}

export function Button({
  text,
  variant,
  size,
  leadingIcon,
  trailingIcon,
  className,
  isLoading = false,
  isDropDown = false,
  ...rest
}: IButton) {
  return (
    <button
      className={twMerge(
        style.container({
          variant,
          size,
        }),
        className,
      )}
      disabled={isLoading || rest.disabled}
      {...rest}
    >
      {isLoading && (
        <span className={style.loading()} role="status" aria-label="loading" />
      )}

      {!isLoading && (
        <div className={style.content()}>
          {leadingIcon && (
            <div className={style.containerIcon()}>{leadingIcon}</div>
          )}
          {isDropDown && text && (
            <span className={style.textDropDown()}>{text}</span>
          )}
          {!isDropDown && text && <span className={style.text()}>{text}</span>}

          {trailingIcon && (
            <div className={style.containerIcon()}>{trailingIcon}</div>
          )}
        </div>
      )}
    </button>
  );
}

Button.displayName = 'Button';

export default Button;
